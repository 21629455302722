import React, {useRef} from 'react'
import './Join.css'
import emailjs from '@emailjs/browser';

const Join = () => {
    const form = useRef()

     const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_0yis9et', 'template_dvi2mjo', form.current, 'QhSjiTX9FbQ9sAdn2')
        .then((result) => {
           alert('Email Sent Successfully')
        }, (error) => {
            console.log(error.text);
        });
  };
  return (
    <div className="Join" id="join-us">
          <div className="left-j">
              <hr/>
              <div>
                  <span className='stroke-text'>READY TO</span>
                  <span>LEVEL UP</span>
              </div>
              <div>
                  <span>YOUR BODY</span>
                  <span className='stroke-text'>WITH US ?</span>
              </div>
          </div>
          <div className="right-j">
              <form ref={form} className="email-container" onSubmit={sendEmail}>
                  <input type="email" name="user_email" placeholder='Enter your email address' id="" />
                  <button type="submit"className='btn btn-j'>Join Now</button>
              </form>
          </div>
    </div>
  )
}

export default Join